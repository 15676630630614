body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

#root {
  height: 100%;
  width: 100%;
}

@primary-color: #233AD8;@link-color: #233AD8;@border-radius-base: 4px;@font-family: "Manrope", sans-serif;@text-color-secondary: #808080;@btn-primary-bg: #233AD8;@btn-disable-color: #fff;@btn-disable-bg: #99B7FF;@form-item-margin-bottom: 44px;@input-height-lg: 64px;@input-padding-horizontal-lg: 20px;@input-placeholder-color: #808080;@input-bg: 1;